<template>
  <button class="flex h-11.5 items-center justify-center rounded-lg" :class="classes" v-bind="attrs">
    <slot></slot>
  </button>
</template>

<script>
import MixinOutlined from "~/mixins/outlined.js";

export default {
  name: "ButtonBasic",
  mixins: [MixinOutlined],

  props: {
    backgroundColor: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  setup() {
    const attrs = useAttrs();
    return { attrs };
  },
  computed: {
    classes() {
      const classes = [];
      classes.push(this.outlined_class);
      if (this.backgroundColor) {
        classes.push(this.backgroundColor);
      }
      return classes.join(" ");
    },
  },
};
</script>

<style scoped>
.card {
  width: 270px;
}
</style>
